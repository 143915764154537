import React from 'react';
import styled from "styled-components";
import logo from '../assets/images/cellerMinifundi.png';

const Banner = ({className}) => {
  return (
    <section className={className}>
      <div className="banner-container">
        <img className="logo" src={logo} alt="Celler del Minifundi" />
      </div>
    </section>
  );
};


export default styled(Banner)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 35em;
  height: 100vh;

  .banner-container {
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .logo {
    height: 30vh;
  }

  @media (min-width: 1080px) {
    .logo {
      height: 40vh;
    }
  }

  @media (max-width: 481px) {
    .logo {
      height: 30vh;
    }
  }
`
