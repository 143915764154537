import Img from 'gatsby-image';
import React from 'react';

const ProductCard = ({ product }) => {
  const { name, text, image, price, other } = product;
  return (
    <li>
      <div className="image-container">
        <Img className="productImg" fluid={image.fluid} alt={name} />
      </div>
      <div className="footer">
        <h3 className="product-title">{name}</h3>
        <p className="description">{text}</p>
        {price ? <p className="price">{price.toFixed(2)}€ *</p> : null}
        {other ? <p className="price">{other}</p> : null}
      </div>
    </li>
  );
};

export default ProductCard;
