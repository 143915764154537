import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const LabelComp = ({ text }) => <div className="label-map">{text}</div>;

export default class GMap extends Component {
  static defaultProps = {
    center: {
      lat: 38.79545,
      lng: -0.39678,
    },
    zoom: 15,
  };
  render() {
    return (
      <div className="google-map-container">
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.GATSBY_API_MAP_KEY }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <LabelComp lat={38.79545} lng={-0.39678} text="Celler del Minifundi" />
        </GoogleMapReact>
      </div>
    );
  }
}
