import React from 'react';
import Img from 'gatsby-image';

export default function Tours({ tour }) {
  const { title, textDesc, priceDesc, extraDesc, image } = tour;
  return (
    <section className="wrapper alt product">
      <section className="spotlight tour-spotlight">
        <div className="image-container">
          <Img
            className="image-product"
            fluid={image.fluid}
            alt="Ruta del Celler del Minifundi"
          />
        </div>
        <div className="content">
          <h2>{title}</h2>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: textDesc.childMarkdownRemark.html,
            }}
          />
          <div
            className="prices-tour"
            dangerouslySetInnerHTML={{
              __html: priceDesc.childMarkdownRemark.html,
            }}
          />
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: extraDesc.childMarkdownRemark.html,
            }}
          />
        </div>
      </section>
    </section>
  );
}
