import React, { Component } from 'react';

import logo from '../assets/images/cellerMinifundi.png';

import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

export const isBrowser = () => typeof window !== 'undefined';

export const getIsAdult = () =>
  isBrowser() && window.localStorage.getItem('gatsbyAdult')
    ? window.localStorage.getItem('gatsbyAdult')
    : false;

const setIsAdult = isAdult =>
  window.localStorage.setItem('gatsbyAdult', isAdult);

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.setState({
      show: !getIsAdult(),
    });
  }

  closeModal = e => {
    e.preventDefault();
    this.setState({
      show: false,
    });

    setIsAdult(true);
  };

  render() {
    return (
      this.state.show && (
        <div id="modal" className="modal">
          <div className="modal-container">
            <div className="modal-content">
              <img className="logo" src={logo} alt="Celler del Minifundi" />
              <p><FormattedMessage id="menorEdad" /></p>
            </div>
            <div className="modal-footer">
              <button
                className="button primary confirm-button"
                onClick={this.closeModal}
              >
                Si
              </button>
              <Link
                className="button secondary cancel-button"
                to="/menor-de-edad"
                state={{ show: false }}
              >
                No
              </Link>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default injectIntl(Modal);
