import React from 'react';

export default function About({title, description}) {
  return (
    <section id="one" className="wrapper about special">
      <div className="inner">
        <header className="major">
          <h1>{title}</h1>
          <div
            className="about-content"
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
          />
        </header>
      </div>
    </section>
  );
}
