import React from 'react';
import ProductCard from './ProductCard';

const Shop = ({ shop }) => {
  return (
    <section id="shop" className="wrapper lotes special">
      <div className="inner">
        <header className="major">
          <h2>{shop.title}</h2>
          <div
            className="catalog-content"
            dangerouslySetInnerHTML={{
              __html: shop.text.childMarkdownRemark.html,
            }}
          />
        </header>
        <ul className="features">
          {shop.products.map((product, index) => {
            return <ProductCard key={index} product={product} />;
          })}
        </ul>
      </div>
    </section>
  );
};

export default Shop;
