import React from 'react';
import Img from 'gatsby-image';

export default function Products({ products }) {
  const cavalldefoc = products.find(
    ({ node }) => node.uniqId === 'cavalldeFoc'
  );
  const vermut = products.find(({ node }) => node.uniqId === 'vermutFerrer');
  const senia = products.find(({ node }) => node.uniqId === 'senia');
  return (
    <section className="wrapper alt product">
      <section className="spotlight">
        <div className="image-container">
          <Img
            className="image-product"
            fluid={cavalldefoc.node.image.fluid}
            alt="Cavall de Foc"
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
        <div className="content">
          <h2>{cavalldefoc.node.title}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: cavalldefoc.node.text.childMarkdownRemark.html,
            }}
          />
        </div>
      </section>
      <section className="spotlight">
        <div className="image-container">
          <Img
            className="image-product"
            fluid={senia.node.image.fluid}
            alt="Senia"
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
        <div className="content">
          <h2>{senia.node.title}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: senia.node.text.childMarkdownRemark.html,
            }}
          />
        </div>
      </section>
      <section className="spotlight">
        <div className="image-container">
          <Img
            className="image-product"
            fluid={vermut.node.image.fluid}
            alt="Vermut Ferrer"
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
        <div className="content">
          <h2>{vermut.node.title}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: vermut.node.text.childMarkdownRemark.html,
            }}
          />
        </div>
      </section>
    </section>
  );
}
