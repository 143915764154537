import { graphql } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';
import React from 'react';
import About from '../components/About';
import Banner from '../components/Banner';
import Contact from '../components/Contact';
import Layout from '../components/Layout';
import Modal from '../components/Modal';
import Products from '../components/Products';
import Shop from '../components/Shop';
import Tours from '../components/Tours';

const IndexPage = ({ data }) => (
  <Layout>
    <Banner />

    <About title={data.about.title} description={data.about.description} />

    <Products products={data.products.edges} />

    <Tours tour={data.tour} />

    <section id="cta" className="wrapper backgroundScroll"></section>

    <Shop shop={data.shop} />

    <section id="contact" className="wrapper contact">
      <div className="inner">
        <Contact />
      </div>
    </section>

    <Modal />
  </Layout>
);

export const query = graphql`
  query($locale: String!) {
    about: contentfulAbout(node_locale: { eq: $locale }) {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
    }
    catalog: contentfulCatalog(node_locale: { eq: $locale }) {
      title
      text {
        childMarkdownRemark {
          html
        }
      }
      lotes {
        name
        text
        price
        image {
          fluid(maxWidth: 350) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    products: allContentfulProduct(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          uniqId
          title
          text {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid(maxHeight: 800) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    tour: contentfulTour(node_locale: { eq: $locale }) {
      title
      textDesc {
        childMarkdownRemark {
          html
        }
      }
      priceDesc {
        childMarkdownRemark {
          html
        }
      }
      extraDesc {
        childMarkdownRemark {
          html
        }
      }
      image {
        fluid(maxHeight: 900) {
          ...GatsbyContentfulFluid
        }
      }
    }
    shop: contentfulShop(node_locale: { eq: $locale }) {
      title
      text {
        childMarkdownRemark {
          html
        }
      }
      products {
        name
        text
        price
        image {
          fluid(maxWidth: 350) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

export default injectIntl(IndexPage);
