import * as emailjs from 'emailjs-com';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import React, { Component } from 'react';
import Recaptcha from 'react-recaptcha';
import GMap from './GMap.js';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      fake: false,
      showAlert: null,
      messageType: null,
      recaptchaLoad: false,
      isVerified: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.verifiedRecaptcha = this.verifiedRecaptcha.bind(this);
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { recaptchaLoad, isVerified } = this.state;

    if (recaptchaLoad && isVerified) {
      const { name, email, message } = this.state;
      const serviceID = 'default_service';
      const templateID = 'emailCellerMinifundi';
      const data = {
        from_name: name,
        from_email: email,
        message_html: message,
      };
      emailjs
        .send(serviceID, templateID, data, process.env.GATSBY_EMAILJS_USERID)
        .then(
          () => {
            this.setState({ messageType: 'success' });
            this.showAlert();
          },
          err => {
            console.log('Error: ', err);
            this.setState({ messageType: 'error' });
            this.showAlert();
          }
        );
    } else {
      this.setState({
        messageType: 'error',
      });
      this.showAlert();
    }
  };

  recaptchaLoaded() {
    this.setState({ recaptchaLoad: true });
  }

  verifiedRecaptcha(response) {
    if (response) {
      this.setState({ isVerified: true });
    }
  }

  showAlert() {
    this.setState({ showAlert: true });
    setTimeout(() => {
      this.setState({
        showAlert: false,
        messageType: null,
      });
    }, 5000);
  }

  render() {
    return (
      <div className="contact section" id="Contact">
        <div className="container">
          <div className="section-head">
            <h2 className="text-center">
              <FormattedMessage id="contactTitle" />
            </h2>
          </div>
          <div className="section-content">
            <div className="contact-form">
              <form onSubmit={this.handleSubmit}>
                <div>
                  <label>
                    <FormattedMessage id="Name" />:
                    <input
                      type="text"
                      name="name"
                      aria-label="name"
                      value={this.state.name}
                      onChange={this.handleInputChange}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    <FormattedMessage id="Email" />:
                    <input
                      type="email"
                      name="email"
                      aria-label="email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    <FormattedMessage id="Message" />:
                    <textarea
                      name="message"
                      aria-label="message"
                      value={this.state.message}
                      onChange={this.handleInputChange}
                    ></textarea>
                  </label>
                </div>
                <div className="recaptcha">
                  <Recaptcha
                    sitekey="6LdnItkZAAAAABhzNTXZTojnkSZlzLHdc1tymCSW"
                    render="explicit"
                    onloadCallback={this.recaptchaLoaded}
                    verifyCallback={this.verifiedRecaptcha}
                  />
                </div>
                <div>
                  <button type="submit">
                    <FormattedMessage id="Send" />
                  </button>
                </div>
              </form>
              {this.state.showAlert ? (
                <div className="alert-container">
                  {this.state.messageType === 'success' ? (
                    <div className="success-box">
                      <span>
                        <FormattedMessage id="contactSuccess" />
                      </span>
                    </div>
                  ) : (
                    <div className="error-box">
                      <span>
                        <FormattedMessage id="contactError" />
                      </span>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            <div className="contact-container-map">
              <div className="map">
                <GMap />
              </div>
              <div className="info">
                <div className="address">
                  <address>
                    <span>Celler del Minifundi </span>
                    <br />
                    03830, Muro d'Alcoi, España
                    <br />
                  </address>
                </div>
                <div className="email-telf">
                  <address>
                    <a href="mailto:cellerminifundi@gmail.com">
                      cellerminifundi@gmail.com
                    </a>
                    <br />
                    <span title="Phone">
                      <FormattedMessage id="Phone" />:
                    </span>{' '}
                    607902235
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Contact);
